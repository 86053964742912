<template>
  <a-modal
    id="meta-data-modal"
    v-model:visible="showMetaDataModal"
    centered
    destroy-on-close
    style="width: 40%"
    title="Meta Data"
    :footer="null"
    @cancel="handleMetaDataModalCancel"
  >
    <a-table
      :columns="metaDataColumns"
      :data-source="metaDataList"
      :pagination="false"
      :scroll="{ x: 'max-content', y: 300 }"
    />
  </a-modal>
  <a-row
    id="line-visibility-container"
    :gutter="[16, 16]"
    class="py-4 px-2 p-md-4"
  >
    <a-col span="24">
      <a-card class="selection-card" bordered>
        <a-row :gutter="[24, 0]">
          <a-col :span="6">
            <a-select
              id="line-visibility-select-task"
              v-model:value="selectedTaskId"
              class="w-100"
              size="small"
              show-search
              placeholder="Select Operation"
              :options="taskOptions"
              :loading="isFetchingTasks"
              :filter-option="filterOption"
            />
          </a-col>
          <a-col :span="6">
            <a-input
              v-model:value="metaValue"
              class="w-100"
              show-search
              placeholder="Meta Value"
              size="small"
            />
          </a-col>
          <a-col :span="4">
            <a-checkbox v-model:checked="checkMeta" size="small">
              Check meta in all operations
            </a-checkbox>
          </a-col>
          <a-col :span="4">
            <a-button
              class="w-100"
              type="primary"
              size="small"
              :disabled="disableUpdate"
              :loading="isLoadingUpdate"
              @click="handleUpdateClick"
            >
              Update
            </a-button>
          </a-col>
        </a-row>
      </a-card>
    </a-col>

    <!-- Video Rendering -->
    <a-col span="24">
      <a-card
        class="selection-card"
        bordered
        title="Line Trace Cycles"
        :loading="isLoadingUpdate"
      >
        <template v-if="lineTraceCycles.length" #extra>
          <a-typography-title :level="5" class="my-0">
            Total Videos: &nbsp;
            <a-tag color="blue">
              {{ lineTraceCycles.length }}
            </a-tag>
          </a-typography-title>
        </template>
        <div class="video-container" @scroll="handleScroll">
          <template v-for="video in lineTraceCycles" :key="video.id">
            <div style="flex-shrink: 0; width: 500px; display: inline-block">
              <a-card id="video-container-card" class="mb-4">
                <template #title>
                  <a-tooltip
                    :title="video?.taskName ? video.taskName : video.fileName"
                  >
                    {{ video?.taskName ? video.taskName : video.fileName }}
                  </a-tooltip>
                </template>
                <template #extra>
                  <a-tooltip title="Show Meta Data">
                    <a-button @click="displayMetaDataModal(video)">
                      <template #icon>
                        <PieChartOutlined />
                      </template>
                    </a-button>
                  </a-tooltip>
                </template>
                <a-row :gutter="[16, 32]">
                  <a-col span="24">
                    <div class="video-wrapper">
                      <video
                        ref="videoRefs"
                        disablePictureInPicture
                        :src="video?.fileURL"
                        crossorigin="anonymous"
                        controls
                      ></video>
                    </div>
                  </a-col>
                  <a-col v-if="lineTraceCycles.length" span="24">
                    <div class="d-flex flex-column">
                      <a-descriptions class="text-start">
                        <a-descriptions-item label="Cycle Identifier" span="3">
                          <a-tag color="blue">
                            {{ formatDate(video?.cycle_identifier) }}
                          </a-tag>
                          <template v-if="video?.metaDataKey">
                            <span class="ml-auto" style="margin-right: 8px">
                              {{ video.metaDataKey }}:
                            </span>
                            <a-tag color="cyan">
                              {{ video.metaDataValue }}
                            </a-tag>
                          </template>
                        </a-descriptions-item>
                        <a-descriptions-item
                          label="Cycle Time"
                          span="3"
                          :label-style="{ width: '6em' }"
                        >
                          <a-tag color="blue">
                            {{
                              dateHelper.formatDate(
                                dateHelper.getTimeFromSeconds(
                                  Math.round(video.total_cycle_time)
                                ),
                                'HH:mm:ss'
                              )
                            }}
                          </a-tag>
                        </a-descriptions-item>
                        <a-descriptions-item
                          label="Missed Steps"
                          span="3"
                          :label-style="{ width: '7em' }"
                        >
                          <a-tag color="blue">
                            {{
                              video.is_good_cycle
                                ? 0
                                : video.missed_steps.length
                            }}
                          </a-tag>
                        </a-descriptions-item>
                      </a-descriptions>
                    </div>
                  </a-col>
                </a-row>
              </a-card>
            </div>
          </template>
        </div>
        <a-empty
          v-if="!lineTraceCycles.length && !isLoadingUpdate"
          :image="simpleImage"
        />
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { PieChartOutlined } from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import dateHelper from 'src/components/shared/Helpers/dateHelper.js';
import spaceMixin from 'src/mixins/handleSpace';
import httpClient from 'src/service/httpClient';
import TelemetryService from 'src/services/telemetry';
import { getSortedTask } from 'src/utils/task';
import { mapActions, mapGetters } from 'vuex';
import { metaDataColumns } from './config';
export default {
  components: {
    PieChartOutlined,
  },

  mixins: [spaceMixin],

  inject: ['toast'],

  props: {
    cycleId: { type: String, default: '' },
    taskId: { type: String, default: '' },
  },

  setup() {
    return {
      metaDataColumns,
      dateHelper,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },

  data() {
    return {
      isLoadingUpdate: false,
      metaValue: null,
      checkMeta: false,
      showVideoControls: false,
      lineTraceCycles: [],
      showMetaDataModal: false,
      metaDataList: [],
      selectedTaskId: null,
      calledUpdate: false,
    };
  },

  computed: {
    ...mapGetters(['isFetchingTasks', 'allTasks', 'organization']),

    taskOptions() {
      return getSortedTask(this.allTasks);
    },

    disableUpdate() {
      return this.isFetchingTasks || !this.selectedTaskId || !this.metaValue;
    },
  },

  created() {
    this.getAllTasks();
  },

  async mounted() {
    if (!this.taskId) return;
    this.selectedTaskId = Number(this.taskId);
    if (!this.cycleId) return;
    this.checkMeta = true;
    await this.fetchLineCycles();
  },

  methods: {
    ...mapActions(['getAllTasks']),

    formatDate(value) {
      if (!value) return '';

      const date = new Date(value);
      return date.toLocaleString('sv-SE', {
        hour12: false,
        timeZone: 'UTC',
      });
    },

    handleMetaDataModalCancel() {
      this.showMetaDataModal = false;
      this.metaDataList = [];
    },

    displayMetaDataModal(record) {
      this.showMetaDataModal = true;
      this.setMetaDataList(record);
    },

    setMetaDataList(record) {
      if (record.metadata && typeof record.metadata === 'object') {
        this.metaDataList = Object.entries(record.metadata).map(
          ([key, value]) => ({
            metaDataKey: key,
            metaDataValue: value,
          })
        );
      } else {
        this.metaDataList = [];
      }
    },

    handleScroll() {
      this.$refs.videoRefs.forEach((video) => {
        video.controls = false;
        video.controls = true;
      });
    },

    metadataKeys(video) {
      if (!Object.prototype.hasOwnProperty.call(video, 'metadata')) return [];
      return Object.keys(video.metadata);
    },

    getMetaDataKey(video, index) {
      return Object.keys(video.metadata)[index];
    },

    getLineVisibilityQueryParams() {
      let params = {
        task: this.selectedTaskId,
        meta_in_all: this.checkMeta,
      };
      if (this.metaValue) {
        params['meta_value'] = this.metaValue;
      } else if (this.cycleId) {
        params['cycle_id'] = Number(this.cycleId);
      }
      return params;
    },

    async handleUpdateClick() {
      if (!this.selectedTaskId)
        return this.toast.info('Please select an operation');
      if (!this.metaValue) return this.toast.info('Please provide meta value.');
      this.calledUpdate = true;
      await this.fetchLineCycles();
    },

    async fetchLineCycles() {
      const queryParams = this.getLineVisibilityQueryParams();
      this.isLoadingUpdate = true;
      const [error, data] = await TelemetryService.getLineTraceCycles(
        queryParams
      );
      this.lineTraceCycles = [];
      if (error) {
        this.isLoadingUpdate = false;
        let errorMessage = 'Failed to retrieve cycles.';
        if (error?.response?.data?.detail)
          errorMessage = error.response.data.detail;
        return this.toast.error(errorMessage);
      }
      await this.setLineTraceCycles(data);
    },

    async setLineTraceCycles(data) {
      this.lineTraceCycles = data;
      if (!this.lineTraceCycles.length) {
        this.isLoadingUpdate = false;
        return this.toast.info('No cycles found.');
      }
      this.parseVideoData();
      this.isLoadingUpdate = false;
      this.setVideoOperationName();
      await this.setVideoUrls();
    },

    setVideoOperationName() {
      this.lineTraceCycles.forEach((item) => {
        const task = this.allTasks.find((task) => task.id == item.task);
        if (task) item.taskName = task.taskName;
      });
    },

    async setVideoUrls() {
      const videoUrlPromises = this.lineTraceCycles.map(async (video) => {
        const { bucket, filePath } = this.getVideoS3Details(video);
        video.fileURL = await this.fetchPresignedUrl(bucket, filePath);
      });

      await Promise.all(videoUrlPromises);
    },

    parseVideoData() {
      for (let video of this.lineTraceCycles) {
        video.metadata = JSON.parse(video.metadata);
        video.missed_steps = JSON.parse(video.missed_steps);
        this.setMetaKeyValue(video);
      }
    },

    setMetaKeyValue(video) {
      for (const [key, value] of Object.entries(video.metadata)) {
        const videoMetaKey = '' + video.meta_key;
        const metaDataKey = '' + key;
        if (videoMetaKey === metaDataKey) {
          video.metaDataKey = metaDataKey;
          video.metaDataValue = value;
          if (!this.metaValue && this.cycleId && !this.calledUpdate)
            this.metaValue = value;
          return;
        }
      }
    },

    getVideoS3Details(video) {
      const { fileName, device_id, task } = video;
      const bucket = `${this.organization}-videos`;
      const filePath = `${device_id}/${task}/Processed/${fileName}`;
      return { bucket, filePath };
    },

    async fetchPresignedUrl(bucket_name, object_path) {
      const payload = {
        bucket_name,
        object_path,
      };
      return new Promise(async (resolve) => {
        const res = await httpClient.post(
          'generic/generate_new_url/',
          payload,
          false,
          false,
          false
        );
        resolve(res.presigned_url || res);
      });
    },
  },
};
</script>

<style scoped>
.selection-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.video-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-width: 100%;
  gap: 35px;
}

.video-wrapper {
  height: 350px !important;
}

.video-wrapper video {
  width: 100%;
  height: 100%;
}

#video-container-card .ant-card-body {
  padding: 0;
}
</style>
